import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
// import PropTypes from "prop-types"
// import { StaticImage } from "gatsby-plugin-image"
import Pdf from "../../../../svg/pdf.svg"
import File from "../../../../svg/upd.svg"

// import Video from "../Video/Video"

const PreviewPageContent = ({ dataContent }) => {
  const alt = dataContent?.titolo

  console.log(dataContent)
  // const [open, setOpen] = React.useState(false)
  // const [play, setPlay] = React.useState(false)

  // function openPopUp() {
  //   setOpen(!open)
  //   setPlay(true)
  // }

  // function closePopUp(e) {
  //   e.preventDefault()
  //   setOpen(false)
  //   setPlay(false)
  // }
  return (
    <section className="preview-content-wrapper">
      <div className="container">
        <div className="row wrapper">
          {/* <div className="col-12">
            <div className="video-cover">
              <StaticImage
                src="../../../../images/test.jpg"
                className="video-cover__image"
                alt="A dinosaur"
              />
            </div> 

            <button onClick={openPopUp} className="play-button">
              <span className="pulse"></span>
              <span className="play-button__icon">
                <span className="button-icon__text">Play</span>
              </span>
            </button> 
            <Video closePopUp={closePopUp} open={open} play={play} />
          </div> */}

          <div className="col-12">
            <div className="">
              {dataContent?.contenuto &&
                (dataContent.contenuto || []).map((item, index) => {
                  if (item?.pdf) {
                    return (
                      <div
                        key={`${item?.pdf?.localFile___NODE}_${index}`}
                        className="pdf-wrapper"
                      >
                        <a
                          href={`https://admin.fiorenzato.app${item?.pdf?.url}`}
                          className="pdf-download__link"
                          download={`https://admin.fiorenzato.app${item?.pdf?.url}`}
                        >
                          {item?.pdf?.ext !== ".upd" ? (
                            <i className="icon">
                              <Pdf />
                            </i>
                          ) : (
                            <i className="icon">
                              <File />
                            </i>
                          )}
                          <span>{item?.titolo}</span>
                        </a>
                      </div>
                    )
                  } else if (item?.foto) {
                    return (
                      <div
                        key={`${item?.foto?.localFile___NODE}_${index}`}
                        className="image-wrapper"
                      >
                        <LazyLoadImage
                          effect="blur"
                          src={`http://admin.fiorenzato.app${item?.foto?.url}`}
                          className="image-item"
                          alt={alt}
                        />
                      </div>
                    )
                  } else if (item?.titolo) {
                    return (
                      <div
                        key={`${item?.titolo?.localFile___NODE}_${index}`}
                        className="image-wrapper"
                      >
                        <h2 className="h2">{item?.titolo}</h2>
                      </div>
                    )
                  } else {
                    return null
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

PreviewPageContent.propTypes = {}

export default PreviewPageContent
