import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PreviewPage = ({ dataPreview }) => {
  const image = getImage(dataPreview?.foto?.localFile)
  return (
    <section className="preview-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="preview-main-screen">
            <GatsbyImage
              image={image}
              className="preview-main-screen__img"
              alt="A dinosaur"
            />
            <h1 className="main-title">{dataPreview?.titolo}</h1>
          </div>
        </div>
      </div>
    </section>
  )
}

PreviewPage.propTypes = {
  dataPreview: PropTypes.object.isRequired,
}

export default PreviewPage
