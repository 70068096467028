import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"

import Seo from "../components/seo"

import PreviewPageMainScreen from "../components/Pages/PreviewPage/PreviewPageMainScreen/PreviewPageMainScreen"
import PreviewPageContent from "../components/Pages/PreviewPage/PreviewPageContent/PreviewPageContent"

const PreviewIndexPage = ({ data }) => (
  <Layout>
    <Seo title="Fiorenzato La passione per l’innovazione, l’amore per il caffè" />
    <PreviewPageMainScreen dataPreview={data?.strapiProdotti} />
    <PreviewPageContent dataContent={data?.strapiProdotti} />
  </Layout>
)

export default PreviewIndexPage

export const query = graphql`
  query Preview($id: String!) {
    strapiProdotti(id: { eq: $id }) {
      titolo
      descrizione
      foto {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
            )
          }
        }
      }
      contenuto
    }
  }
`
